import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelAndConfirmButton from '../../Components/CancelAndConfirmButton/CancelAndConfirmButton';
import { EventAdministrationItems } from '../../Enums/EventAdministrationItems.enum';
import { CircularProgress, List } from '@material-ui/core';
import TriggerActionRow from '../../Components/TriggerActionRow/TriggerActionRow';
import useEventManagement from '../../Hooks/EventManagement/useEventManagement';
import { resetEventBadges } from '../../Services/http/event.service';
import { downloadUsersListBadgesClaim } from '../../Services/http/statistics.service';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() =>
    createStyles({
        accordion: {
            display: 'flex',
            flexDirection: 'column',
        },
        optionsList: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 20,
        },
        loaderWrapper: {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transaction: 'translateX(-50%), translateY(-50%)',
        },
    }),
);

const PassportAccordionContainer = () => {
    const classes = useStyles();
    const { state, dispatch } = useEventManagement();
    const [disabled, setDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [resetEventPassports, setResetEventPassports] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const onReset = (reset: boolean) => {
        setResetEventPassports(reset);
    };

    const onDownload = async () => {
        setIsLoading(true);
        await downloadUsersListBadgesClaim(state.eventCode)
            .then((response) => {
                setIsLoading(false);
                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'passport_claim.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch((e: any) => {
                try {
                    console.error('An error occurred:', e.message);
                    setIsLoading(false);
                    const message = e?.response?.data?.message
                        ? `Download failed: ${e.response.data.message}`
                        : `Download failed: ${e.message}`;
                    enqueueSnackbar(message, {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                } catch (parseError) {
                    console.error('An unexpected error occurred.');
                }
            });
    };

    const onCancel = async () => {
        setResetEventPassports(false);
    };

    const onSubmit = async () => {
        if (resetEventPassports) {
            setIsLoading(true);
            resetEventBadges(state.eventCode)
                .then(() => {
                    setIsLoading(false);
                    setResetEventPassports(false);
                    enqueueSnackbar('Badge collection for this event has been successfully reset', {
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                })
                .catch((e: Error) => {
                    console.error('An error occurred:', e.message);
                    setIsLoading(false);
                    enqueueSnackbar('The reset has failed. Please contact the support team!', {
                        variant: 'error',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },
                    });
                    throw new Error('Something went wrong. Please try again later.');
                });
        }
    };

    return (
        <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>
                    <strong>{EventAdministrationItems.PASSPORT}</strong>
                </Typography>
            </AccordionSummary>
            <List className={classes.optionsList}>
                {resetEventPassports}
                <TriggerActionRow
                    label={'Reset badge collection status for the whole event'}
                    buttonLabel={'Reset'}
                    confirmLabel={'Reset triggered'}
                    onTrigger={onReset}
                    triggered={resetEventPassports}
                ></TriggerActionRow>
            </List>
            <List className={classes.optionsList}>
                <TriggerActionRow
                    label={'User list with badge and claim status'}
                    buttonLabel={'Download'}
                    confirmLabel={''}
                    onTrigger={onDownload}
                ></TriggerActionRow>
            </List>
            <CancelAndConfirmButton
                onCancel={onCancel}
                onSubmit={onSubmit}
                disabled={disabled || !resetEventPassports}
            />
            {isLoading && (
                <div className={classes.loaderWrapper}>
                    <CircularProgress />
                </div>
            )}
        </Accordion>
    );
};

export default PassportAccordionContainer;
