import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { CardCreation } from '../../Components/CardCreation/CardCreation';
import { UPDATE_AI_MODEL } from '../../Contexts/useCaseContext';
import useCaseManagement from '../../Hooks/UseCaseManagement/UseCaseManagement';
import { LlmOptions } from '../../Models/useCase.model';
import { getLlmOptions } from '../../Services/http/useCase.services';

export const ModelAiForm: React.FC = () => {
    const { state, dispatch } = useCaseManagement();
    const theme = useTheme();
    const [llmProvidersList, setLlmProvidersList] = useState<Array<{ name: string; id: number }>>(
        [],
    );
    const [llmModelsList, setLlmModelsList] = useState<Array<{ name: string; id: number }>>([]);
    const [llmRegionsList, setLlmRegionsList] = useState<Array<{ name: string; id: number }>>([]);

    const selectStyle = {
        width: '250px',
        borderRadius: '0.25rem',
    };

    useEffect(() => {
        getAllLlmOptions();
    }, []);

    async function getAllLlmOptions() {
        await getLlmOptions()
            .then((response) => {
                const options: LlmOptions = response.data;
                const allProviders = options.options.map((option) => ({
                    id: option.id,
                    name: option.name,
                }));
                setLlmProvidersList(allProviders);
                const allModels = options.options.flatMap((option) => option.models);
                setLlmModelsList(allModels);
                const allRegions = options.options.flatMap((option) => option.regions);
                setLlmRegionsList(allRegions);
            })
            .catch((e) => {
                const errorMessage = 'An error occurred while processing your request.';
                console.error(errorMessage);
                if (e.response?.data?.message) {
                    console.error(`Error Message: ${e.response.data.message}`);
                }
            });
    }

    function handleChangeProvider(newProvider: number | null) {
        updateModelInfo(newProvider, state.aiModel.model_id, state.aiModel.region_id);
    }

    function handleChangeModel(newModel: number | null) {
        updateModelInfo(state.aiModel.provider_id, newModel, state.aiModel.region_id);
    }

    function handleChangeRegion(newRegion: number | null) {
        updateModelInfo(state.aiModel.provider_id, state.aiModel.model_id, newRegion);
    }

    function updateModelInfo(
        newProviderId: number | null,
        newModelId: number | null,
        newRegionId: number | null,
    ) {
        dispatch({
            type: UPDATE_AI_MODEL,
            payload: {
                provider_id: newProviderId,
                model_id: newModelId,
                region_id: newRegionId,
            },
        });
    }

    return (
        <CardCreation
            title="AI Model"
            popoverContent="Set all information about the model of ai used by the character"
        >
            <Grid item container direction="row">
                <Grid
                    item
                    container
                    direction="column"
                    gap={2}
                    xs={8}
                    justifyContent="space-between"
                >
                    <Grid item>
                        {llmProvidersList && (
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="llm-provider-select">Provider</InputLabel>
                                <Select
                                    labelId="llm-provider-select"
                                    id="llm-provider-select"
                                    value={state.aiModel.provider_id ?? ''}
                                    onChange={(e) => handleChangeProvider(e.target.value as number)}
                                    sx={selectStyle}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {llmProvidersList.map(
                                        (provider: { name: string; id: number }) => {
                                            return (
                                                <MenuItem value={provider.id} key={provider.name}>
                                                    {provider.name}
                                                </MenuItem>
                                            );
                                        },
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item>
                        {llmModelsList && (
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="llm-model-select">Model</InputLabel>
                                <Select
                                    labelId="llm-model-select"
                                    id="llm-model-select"
                                    value={state.aiModel.model_id ?? ''}
                                    onChange={(e) => handleChangeModel(e.target.value as number)}
                                    sx={selectStyle}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {llmModelsList.map((model: { name: string; id: number }) => {
                                        return (
                                            <MenuItem value={model.id} key={model.name}>
                                                {model.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item>
                        {llmRegionsList && (
                            <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                                <InputLabel id="llm-region-select">Region</InputLabel>
                                <Select
                                    labelId="llm-region-select"
                                    id="llm-region-select"
                                    value={state.aiModel.region_id ?? ''}
                                    onChange={(e) => handleChangeRegion(e.target.value as number)}
                                    sx={selectStyle}
                                    IconComponent={ExpandMoreIcon}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {llmRegionsList.map((region: { name: string; id: number }) => {
                                        return (
                                            <MenuItem value={region.id} key={region.name}>
                                                {region.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </CardCreation>
    );
};
