import React, { FC, useState } from 'react';
import ActionButton from '../../Components/ActionButton/ActionButton';
import ActionConfirmDialog from '../../Components/Dialogs/ActionConfirmDialog/ActionConfirmDialog';
import CompleteDialog from '../../Components/Dialogs/ActionCompleteDialog/ActionCompleteDialog';
import { deleteRoom } from '../../Services/http/content.service';
import { Room } from '../../Models/socket.model';

interface Props {
    onCompleteCallback: () => void;
    room: Room;
}

const DeleteRoomContainer: FC<Props> = ({ onCompleteCallback, room }) => {
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [isCompleteDialogOpen, setIsCompleteDialogOpen] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleConfirmClick = async () => {
        deleteRoom(room.id)
            .then(() => {
                setIsSuccess(true);
                setIsConfirmDialogOpen(false);
                toggleCompleteDialog();
            })
            .catch((err: Error) => {
                console.error('an error occured:', err.message);
                setIsSuccess(false);
                setIsConfirmDialogOpen(false);
                toggleCompleteDialog();
                throw new Error('Something went wrong. Please try again later.');
            });
    };

    const toggleConfirmDialog = () => {
        setIsConfirmDialogOpen(!isConfirmDialogOpen);
    };

    const toggleCompleteDialog = () => {
        setIsCompleteDialogOpen(!isCompleteDialogOpen);
    };

    const handleCloseCompleteDialog = () => {
        onCompleteCallback();
        toggleCompleteDialog();
    };

    return (
        <>
            <ActionButton
                icon
                onClick={toggleConfirmDialog}
                text={'Delete Reality linking'}
                id="selected-room-delete-reality-linking"
            />
            <ActionConfirmDialog
                open={isConfirmDialogOpen}
                onClose={toggleConfirmDialog}
                onConfirm={handleConfirmClick}
                title={'Delete Reality linking'}
                content={`Reality linking "${room.name}" will be deleted. Do you want to continue?`}
            />
            <CompleteDialog
                title="Delete Reality linking"
                successMessages={
                    isSuccess ? [`Reality linking "${room.name}" has been deleted`] : []
                }
                errorMessages={isSuccess ? [] : ['An error occurred.']}
                open={isCompleteDialogOpen}
                onClose={handleCloseCompleteDialog}
            />
        </>
    );
};

export default DeleteRoomContainer;
