import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
    AppBar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Menu,
    Toolbar,
    Typography,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { protectedRoutes } from '../../App';
import { getLocalStorageItemValue } from '../../Services/local-storage.service';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarLogo } from '../../star_logo.svg';
import { createStyles, makeStyles } from '@mui/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: theme.palette.action.hover,
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        toolbarButtons: {
            display: 'flex',
            marginRight: theme.spacing(0),
        },
        title: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        profileList: {
            width: 300,
            padding: 0,
        },
        listSubheader: {
            fontWeight: 'bold',
        },
        rowCenter: {
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            alignItems: 'center',
        },
        toolbar: {
            justifyContent: 'space-between',
        },
        protectedLink: {
            color: '#85adff',
        },
    }),
);

interface Props {
    onLogout: () => void;
    helpActionElement: React.ReactElement;
}

const HeaderNavigation: FC<Props> = ({ onLogout, helpActionElement }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentUser: any = JSON.parse(getLocalStorageItemValue('currentUser'));
    const { t } = useTranslation();
    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (evt: any) => {
        setAnchorEl(evt.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppBar
                position="sticky"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.rowCenter}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            id="burger-menu"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.rowCenter}>
                            <StarLogo />
                            <Divider orientation="vertical" flexItem />
                            <Typography variant="h6" className={classes.title}>
                                {t('loginPage.title')}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.toolbarButtons}>
                        {helpActionElement}
                        <IconButton
                            color="inherit"
                            aria-controls="profile-menu"
                            id="profile-icon"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                        >
                            <PersonOutlineOutlinedIcon />
                        </IconButton>
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <List onClick={handleMenuClose} className={classes.profileList}>
                                <ListSubheader className={classes.listSubheader}>
                                    User Name
                                </ListSubheader>
                                <ListItem id="profile-username-value">
                                    <ListItemText primary={currentUser.username} />
                                </ListItem>
                                <ListSubheader className={classes.listSubheader}>
                                    Roles
                                </ListSubheader>
                                {currentUser.roles.map((role: any) => {
                                    let transformedRole = role.role;

                                    if (role.role === 'admin') {
                                        transformedRole = 'platform-admin';
                                    } else if (role.role === 'event-admin') {
                                        transformedRole = 'domain-admin';
                                    }

                                    return (
                                        <ListItem key={role.role} id="profile-roles-value">
                                            <ListItemText primary={transformedRole} />
                                        </ListItem>
                                    );
                                })}

                                <ListSubheader className={classes.listSubheader}>
                                    Domain
                                </ListSubheader>
                                <ListItem id="profile-domain-value">
                                    <ListItemText primary={currentUser.event_code} />
                                </ListItem>
                            </List>
                        </Menu>
                        <IconButton id="logout-icon" onClick={onLogout}>
                            <LogoutIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} id="burger-menu-left-arrow">
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    {protectedRoutes.map((route) => (
                        <ListItem button key={route.name}>
                            <Link to={route.path} className={classes.protectedLink}>
                                <ListItemText primary={route.name} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};
export default HeaderNavigation;
