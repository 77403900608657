import React, { FC, useContext, useEffect, useState } from 'react';
import LoginForm from '../../Components/LoginForm/LoginForm';
import Grid from '@material-ui/core/Grid';
import * as authService from '../../Services/http/auth.service';
import * as contentService from '../../Services/http/content.service';

import { AuthContext, UPDATE_CURRENT_USER } from '../../Contexts/authContext';
import { useHistory, useLocation } from 'react-router-dom';
import {
    setLocalStorageItemValue,
    getLocalStorageItemValue,
} from '../../Services/local-storage.service';
import { ProviderResponse } from '../../Models/auth.model';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& div[class*="MuiGrid-spacing"]': {
                margin: 0,
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    flexWrap: 'nowrap',
                },
            },
            '& div[class*="MuiGrid-item"]': {
                padding: '0 24px',
            },
        },
        item: {
            width: '100%',
        },
    }),
);

const LoginContainer: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const location: any = useLocation();

    const [providers, setProviders] = useState<ProviderResponse>();
    const [loading, setLoading] = useState<boolean>(true);

    const { from } = location.state || { from: { pathname: '/' } };

    const oauthLoginError = getLocalStorageItemValue('oauthLoginError') || '';
    setLocalStorageItemValue('oauthLoginError', '');

    const [errorResponse, setErrorResponse] = useState('');
    const [errorResponseOauth, setErrorResponseOauth] = useState(oauthLoginError || '');
    const { state, dispatch } = useContext(AuthContext);

    useEffect(() => {
        const { currentUser } = state;
        if (!currentUser && !providers) {
            const fetchAll = async () =>
                await authService
                    .getProviders(authService.FrontendTypes.ADMIN)
                    .then((response: any) => {
                        if (response) {
                            const orderPromises = response.data.providers.map((provider: any) =>
                                contentService
                                    .getFile('sso_button', provider.button_filename)
                                    .then((response: any) => {
                                        contentService
                                            .imageFileReader(response?.data)
                                            .then((res) => (provider.button_image = res));
                                    }),
                            );
                            Promise.all(orderPromises).then(() => {
                                setProviders(response.data);
                                setLoading(false);
                            });
                        }
                    })
                    .catch((err: any) => {
                        try {
                            const errorMessage = err.message || 'An unknown error occurred';
                            console.error(`Error: ${errorMessage}`);
                        } catch (parseError) {
                            console.error('An unexpected error occurred.');
                        }
                    });

            fetchAll();
        }
    }, [providers, state]);

    const onSubmit = (username: string, password: string, eventName?: string) => {
        authService
            .login(username.trim(), password, eventName?.trim())
            .then((res: any) => {
                if (res.data) {
                    const { user_data } = res.data;
                    setLocalStorageItemValue('currentUser', JSON.stringify(user_data));
                    dispatch({ type: UPDATE_CURRENT_USER, payload: user_data });
                    const urlParam = new URLSearchParams(location.search).get('redirectTo');
                    if (urlParam) {
                        window.location.replace(`${urlParam}?auth=${user_data.jwt_token}`);
                    } else {
                        history.replace(from);
                    }
                }
            })
            .catch((err: any) => {
                setErrorResponse(err?.response?.data?.message);
            })
            .finally(() => setErrorResponseOauth(''));
    };

    return (
        <div className={classes.root}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                spacing={6}
            >
                <Grid item className={classes.item}>
                    {!loading && (
                        <LoginForm
                            onSubmit={onSubmit}
                            loginFailMsg={errorResponse}
                            loginOauthFailMsg={errorResponseOauth}
                            providers={providers}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default LoginContainer;
